const state = {
    loading: false,
    snackbar: {
        show: false,
        body: 'text',
        type: 'error',
    },
    editModal: {
        show: false,
        title: 'Edit',
        type: 'text-field',
        options: {},
        field: {
            entityType: null,
            entityId: null,
            type: 'string',
            field: null,
            value: null,
        },
        onSuccess: () => {},
        onError: () => {},
    },
};

const getters = {
  snackbar: () => state.snackbar,
  editModal: () => state.editModal,
  loading: () => state.loading,
};

const actions = {

};

const mutations = {
  SNACKBAR({}, snackbar) {
    state.snackbar = snackbar;
  },
  HIDE_SNACKBAR({}) {
    state.snackbar.show = false;
  },
  SHOW_EDIT_MODAL({}, payload) {
      state.editModal = payload;
  },
  HIDE_EDIT_MODAL({}) {
    state.editModal.show = false;
  },
  SHOW_LOADING({}) {
    state.loading = true;
  },
  HIDE_LOADING({}) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
