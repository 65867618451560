// axios
import axios from 'axios'
import Vue from 'vue'

const baseUrl = (process.env.VUE_APP_BASE_URL) ? process.env.VUE_APP_BASE_URL : 'http://localhost';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: '/',
  baseURL: baseUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('accessToken');
        window.location.href = '/auth/login';
        throw error;
    } else {
        return Promise.reject(error);
    }
});

Vue.prototype.$http = axiosIns

export default axiosIns
