import Vue from 'vue';
import Vuex from 'vuex'
import app from './modules/app';
import user from './modules/user';
import other from './modules/other';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    other,
  },
});


Vue.use(store);

export default store
