<template>
  <body>
      <div id="app">
          <router-view></router-view>
      </div>
  </body>
</template>

<script>
export default {};
</script>
