const state = {
    linkbuilders: [],
    createContactModal: {
        show: false,
        form: {
            donor_domain_id: '',
            type: 'email',
            value: '',
            position: '',
            status: 'new',
            first_name: '',
            notes: '',
        },
        onSuccess: () => {},
        onError: () => {},
    },
};

const getters = {
  linkbuilders: () => state.linkbuilders,
    createContactModal: () => state.createContactModal,
};

const actions = {};

const mutations = {
  LINKBUILDERS({}, linkbuilders) {
    state.linkbuilders = linkbuilders;
  },
  SHOW_CONTACT_CREATE_MODAL({}) {
    state.createContactModal.show = true;
  },
  HIDE_CONTACT_CREATE_MODAL({}) {
    state.createContactModal.show = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
