import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";

// ---- BEGIN GGT ------
const Login = () => import("@/pages/Auth/Login.vue");
const Dashboard = () => import("@/pages/Dashboard/Dashboard.vue");
const DonorList = () => import("@/pages/DataManagement/Donor/DonorList.vue");
const DonorItem = () => import("@/pages/DataManagement/Donor/DonorItem.vue");
const ScriptSettings = () => import("@/pages/DataManagement/Script/Settings.vue");

// users
const UserList = () => import("@/pages/DataManagement/User/UserList.vue");
const CreateUser = () => import("@/pages/DataManagement/User/CreateUser.vue");
const EditUser = () => import("@/pages/DataManagement/User/EditUser.vue");

// projects
const ProjectList = () => import("@/pages/DataManagement/Project/ProjectList.vue");
const CreateProject = () => import("@/pages/DataManagement/Project/CreateProject.vue");
const EditProject = () => import("@/pages/DataManagement/Project/EditProject.vue");

// niches
const NicheList = () => import("@/pages/DataManagement/Niche/NicheList.vue");
const CreateNiche = () => import("@/pages/DataManagement/Niche/CreateNiche.vue");
const EditNiche = () => import("@/pages/DataManagement/Niche/EditNiche.vue");

// competitors
const CompetitorList = () => import("@/pages/DataManagement/Competitor/CompetitorList.vue");
const CreateCompetitor = () => import("@/pages/DataManagement/Competitor/CreateCompetitor.vue");
const EditCompetitor = () => import("@/pages/DataManagement/Competitor/EditCompetitor.vue");

const MyProfile = () => import("@/pages/User/MyProfile.vue");
// ---- END GGT ------

// Dashboard pages
const Dashboard2 = () => import("../views/Dashboard/Dashboard.vue");
const Discover = () => import("../views/Dashboard/Discover.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const Sales = () => import("../views/Dashboard/Sales.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Messages = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Messages.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/NewProduct.vue"
  );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/messages",
      name: "Messages",
      component: Messages,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "auth/login",
      name: "Login",
      component: Login,
      meta: {
          hideFooter: true,
      }
    },
    // {
    //   path: "/pages/authentication/signup/basic",
    //   name: "SignUpBasic",
    //   component: SignUpBasic,
    // },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/donors",
    component: DashboardLayout,
    children: [
      {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
              groupName: "Dashboards",
              hideFooter: true,
          },
      },
      {
          path: "script-settings",
          name: "ScriptSettings",
          component: ScriptSettings,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "donors",
          name: "DonorList",
          component: DonorList,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "donors/:id",
          name: "DonorItem",
          component: DonorItem,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "projects",
          name: "ProjectList",
          component: ProjectList,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "projects/create",
          name: "CreateProject",
          component: CreateProject,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
        {
            path: "projects/:id",
            name: "EditProject",
            component: EditProject,
            meta: {
                groupName: "Data Management",
                hideFooter: true,
            },
        },
      {
          path: "users",
          name: "UserList",
          component: UserList,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "users/create",
          name: "CreateUser",
          component: CreateUser,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
        {
            path: "users/:id",
            name: "EditUser",
            component: EditUser,
            meta: {
                groupName: "Data Management",
                hideFooter: true,
            },
        },
      {
          path: "niches",
          name: "NicheList",
          component: NicheList,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "niches/create",
          name: "CreateNiche",
          component: CreateNiche,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
        {
            path: "niches/:id",
            name: "EditNiche",
            component: EditNiche,
            meta: {
                groupName: "Data Management",
                hideFooter: true,
            },
        },
      {
          path: "competitors",
          name: "CompetitorList",
          component: CompetitorList,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "competitors/create",
          name: "CreateCompetitor",
          component: CreateCompetitor,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "competitors/:id",
          name: "EditCompetitor",
          component: EditCompetitor,
          meta: {
              groupName: "Data Management",
              hideFooter: true,
          },
      },
      {
          path: "my-profile",
          name: "MyProfile",
          component: MyProfile,
          meta: {
              hideFooter: true,
          },
      },
      {
        path: "pages/dashboards/analytics",
        name: "Analytics",
        component: Dashboard2,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "pages/dashboards/discover",
        name: "Discover",
        component: Discover,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/dashboards/sales",
        name: "Sales",
        component: Sales,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/ecommerce/products/new-product",
        name: "NewProduct",
        component: NewProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "OrderList",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/details",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
          groupName: "Ecommerce",
        },
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authBasicPages,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
