<template>
    <v-snackbar
        v-model="appSnackbar.show"
        :color="color"
    >
        {{ appSnackbar.body }}
    </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: "app-snackbar",
    computed: {
        ...mapState({
            appSnackbar: (state) => state.app.snackbar,
        }),
        color() {
            if (this.appSnackbar.type === 'error') {
                return 'red';
            } else if (this.appSnackbar.type === 'success') {
                return 'green';
            } else {
                return 'orange';
            }
        }
    }
};
</script>
