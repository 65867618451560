<template>
    <v-dialog
        v-model="createContactModal.show"
        max-width="500"
    >
        <v-card>
            <v-card-title class="text-h5">
                Create contact
            </v-card-title>

            <v-card-text>
                <v-select
                    v-model="createContactModal.form.type"
                    :items="types"
                    outlined
                    dense
                    label="Type"
                ></v-select>
                <v-select
                    v-model="createContactModal.form.status"
                    :items="statuses"
                    outlined
                    dense
                    label="Status"
                ></v-select>
                <v-text-field
                    v-model="createContactModal.form.value"
                    dense
                    outlined
                    label="Value"
                ></v-text-field>
                <v-text-field
                    v-model="createContactModal.form.position"
                    dense
                    outlined
                    label="Position"
                ></v-text-field>
                <v-text-field
                    v-model="createContactModal.form.first_name"
                    dense
                    outlined
                    label="First name"
                ></v-text-field>
                <v-textarea
                    v-model="createContactModal.form.notes"
                    dense
                    outlined
                    label="Notes"
                ></v-textarea>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    @click="close()"
                >
                    Close
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="save()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import axiosIns from "@/plugins/axios.js";
export default {
  name: "create-contact-modal",
    data() {
      return {
          types: [
              { text: 'Email', value: 'email'},
              { text: 'Twitter', value: 'twitter'},
              { text: 'Linkedin', value: 'linkedin'},
              { text: 'Contact form', value: 'contact_form'},
          ],
          statuses: [
              { text: 'New', value: 'new'},
              { text: 'Active', value: 'active'},
              { text: 'Never reply', value: 'never_reply'},
              { text: 'Waiting for replay', value: 'waiting_for_replay'},
          ],
      }
    },
    computed: {
        ...mapState({
            createContactModal: (state) => state.other.createContactModal,
        }),
    },
    methods: {
        close() {
            this.$store.commit('other/HIDE_CONTACT_CREATE_MODAL');
        },
        save() {
            axiosIns.post('/api/donor-contacts', this.createContactModal.form)
                .then((response) => {
                    this.createContactModal.onSuccess(response.data.donorContact);
                    this.$store.commit('other/HIDE_CONTACT_CREATE_MODAL');
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                    this.createContactModal.onError(error);
                });
        },
    },
};
</script>
