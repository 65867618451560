<template>
    <v-dialog
        v-model="appEditModal.show"
        max-width="290"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ evaluateTitle(appEditModal.title) }}
            </v-card-title>

            <v-card-text>
                <template v-if="appEditModal.type === 'text-field'">
                    <v-text-field
                        v-model="appEditModal.field.value"
                        dense
                        outlined
                        :label="evaluateFieldLabel(appEditModal.field.field)"
                    ></v-text-field>
                </template>
                <template v-if="appEditModal.type === 'text-area'">
                    <v-textarea
                        v-model="appEditModal.field.value"
                        dense
                        outlined
                        :label="evaluateFieldLabel(appEditModal.field.field)"
                    ></v-textarea>
                </template>
                <template v-if="appEditModal.type === 'selectSingle'">
                    <v-select
                        v-model="appEditModal.field.value"
                        :items="appEditModal.options.items"
                        :item-text="appEditModal.options.text"
                        :item-value="appEditModal.options.value"
                        outlined
                        dense
                    ></v-select>
                </template>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    @click="close()"
                >
                    Close
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="save()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import axiosIns from "@/plugins/axios.js";
export default {
  name: "app-edit-modal",
    data: () => {
      return {
          matchMachineAndHumanNames: {
              price_an: 'Price AN',
              price_bn: 'Price BN',
              example_of_published_article: 'Example of published article',
              linkbuilder_user_id: 'Linkbuilder',
              first_name: 'First name',
          }
      };
    },
    computed: {
        ...mapState({
            appEditModal: (state) => state.app.editModal,
        }),
    },
    methods: {
      evaluateTitle(machineName) {
          if (machineName in this.matchMachineAndHumanNames) {
              return this.matchMachineAndHumanNames[machineName];
          }
          return machineName;
      },
      evaluateFieldLabel(machineName) {
          if (machineName in this.matchMachineAndHumanNames) {
              return this.matchMachineAndHumanNames[machineName];
          }
          return machineName;
      },
        close() {
            this.$store.commit('app/HIDE_EDIT_MODAL');
        },
        save() {
            axiosIns.post('/api/custom/entity-field/edit', this.appEditModal.field)
                .then((response) => {
                    this.appEditModal.onSuccess(response.data.entity);
                    this.$store.commit('app/HIDE_EDIT_MODAL');
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                    console.log('error');
                    this.appEditModal.onError(error);
                });
        },
    },
};
</script>
